/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import { CalenderDropdown } from './custom/CalenderDropdown'

//this month 
const s_month = new Date()
const e_month = new Date()
const this_month_end = new Date(e_month.getFullYear(), e_month.getMonth() + 1, 0)
const this_month_start = new Date(s_month.setDate(1))

type SubProps = {
  color: string
  title: string
  subtitle: string
  status: number
}

const Subscription: React.FC<SubProps> = ({ color, title, subtitle, status }) => {
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [formInput, setFormInput] = useState({
    DateStart: this_month_start.toISOString().slice(0, 10),
    DateEnd: '',
  })
  const selectionRange = {
    startDate: this_month_start,
    endDate: this_month_end,
    key: 'data',
  }
  const [dateRange, setDateRange] = useState(selectionRange)

  const [counts, setCounts] = useState([])
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios
      .post(`/subscription/search-subscription-count/${status}`, formInput)
      .then((resp) => {
        setCounts(resp.data.count)
        const delayDebounceFn = setTimeout(() => {
          setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    //Refresh 5min per time
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, formInput, status, refresh])
  return (
    <div className={`d-flex align-items-center bg-light-${color} rounded p-5 mb-7 position-relative`}>
      <span className={`fw-bolder text-${color} fs-1 me-10`}>
        {loading ?
          <span className={`spinner-border text-${color}`}></span>
          :
          <span className={`fw-bolder mb-2 text-${color}`}>
            {formInput.DateStart ? counts : 0}
          </span>
        }
      </span>

      <div className='flex-grow-1 me-2'>
        <Link to='#' className={`fw-bolder text-gray-800 text-hover-${color} fs-6`}>
          {title}
        </Link>
        <span className='fw-bold d-block'>{subtitle}</span>
      </div>
      <button
        type='button'
        className={`btn btn-sm btn-icon btn-color-${color} btn-active-${color} position-absolute border-0 top-0`}
        style={{ right: 10 }}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1' />
      </button>
      <CalenderDropdown formInput={formInput} setFormInput={setFormInput} dateRange={dateRange} setDateRange={setDateRange} color={color} />
    </div>
  )
}

const SubscriptionDash: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Subscriptions</h3>
      </div>
      <div className='card-body pt-0'>
        <Subscription
          color='primary'
          title='Active'
          subtitle='Subscription'
          status={1}
        />
        <Subscription
          color='danger'
          title='Close'
          subtitle='Subscription'
          status={3}
        />
      </div>
    </div>
  )
}

export { SubscriptionDash }


