import React from 'react'
import { useHistory } from 'react-router-dom'
// import {KTSVG} from '../../.././_metronic/helpers'

const SubscriptionsItem = ({
  userCan,
  servicetype,
  showId,
  i,
  num_page
}) => {
  const history = useHistory()
  return (
    <>
      {(i > showId - 1 && (i < showId + num_page)) &&
        <tr onClick={() => { history.push(`/customer/${servicetype.CustomerID}/${servicetype.customer_agent_id}/detail/subscription/${servicetype.id}/detail`) }} className='cursor-pointer'>
          <td>{servicetype.id}</td>
          <td>{servicetype.SubscriptionName}</td>
          <td>{servicetype.customer}</td>
          <td>{servicetype.tariff}</td>
          <td>
            <span
              style={{
                backgroundColor: `${servicetype.serviceStatusBackColor}`, //#009ef7
                fontSize: '0.85rem',
                fontWeight: '600',
                padding: '0.4em 0.75em',
                borderRadius: '0.5rem',
                color: `white`, //#f1faff
                lineHeight: 1,
                display: 'inline-block',
              }}
            >
              {servicetype.serviceStatus}
            </span>
          </td>
        </tr>
      }
    </>
  )
}

export default SubscriptionsItem