import React from 'react';

export interface Props {
  page: number;
  totalPages: number;
  handlePagination: (page: number) => void;
}
export const PaginationComponent: React.FC<Props> = ({
  page,
  totalPages,
  handlePagination,
}) => {
  return (
    <ul className='pagination'>
      {page !== 1 && (
        <li className='page-item previous'>
          <a href='#!' className='page-link' onClick={() => handlePagination(page - 1)}>
            <i className='previous'></i>
          </a>
        </li>
      )}
      <li className={`page-item ${page === 1 ? 'active' : ''}`}>
        <a href='#!' className='page-link' onClick={() => handlePagination(1)}>
          1
        </a>
      </li>

      {page > 3 && <li className='page-item' >...</li>}
      {page === totalPages && totalPages > 3 && (
        <li className={`page-item`}>
          <a href='#!' className='page-link' onClick={() => handlePagination(page - 2)}>
            {page - 2}
          </a>
        </li>
      )}
      {page > 2 && (
        <li className={`page-item`}>
          <a href='#!' className='page-link' onClick={() => handlePagination(page - 1)}>
            {page - 1}
          </a>
        </li>
      )}
      {page !== 1 && page !== totalPages && (
        <li className={`page-item active`}>
          <a href='#!' className='page-link' onClick={() => handlePagination(page)}>
            {page}
          </a>
        </li>
      )}
      {page < totalPages - 1 && (
        <li className={`page-item`}>
          <a href='#!' className='page-link' onClick={() => handlePagination(page + 1)}>
            {page + 1}
          </a>
        </li>
      )}
      {page === 1 && totalPages > 3 && (
        <li className={`page-item`}>
          <a href='#!' className='page-link' onClick={() => handlePagination(page + 2)}>
            {page + 2}
          </a>
        </li>
      )}
      {page < totalPages - 2 && <li className='page-item' >...</li>}

      <li className={`page-item ${page === totalPages ? 'active' : ''}`}>
        <a href='#!' className='page-link' onClick={() => handlePagination(totalPages)}>
          {totalPages}
        </a>
      </li>

      {page !== totalPages && (<li className='page-item next'>
        <a href='#!' className='page-link' onClick={() => handlePagination(page + 1)}>
          <i className='next'></i>
        </a>
      </li>
      )}
    </ul>
  );
};
export const MyPagination = PaginationComponent;