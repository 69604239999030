import React, { Suspense, lazy } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import NoPermission from '../../components/NoPermission'
import { RootState } from '../../setup'
import { FallbackView } from '../../_metronic/partials'
import { UserModel } from '../modules/auth/models/UserModel'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const userCan = {
    administratorList: user.permissions.includes('Administrator List'),
    administratorView: user.permissions.includes('Administrator View'),
    roleList: user.permissions.includes('Role List'),
    roleView: user.permissions.includes('Role View'),
    menuList: user.permissions.includes('Menu List'),
    permissionList: user.permissions.includes('Permission List'),
    venderList: user.permissions.includes('Vender List'),
    serviceList: user.permissions.includes('Service List'),
    bill_cycleList: user.permissions.includes('Bill Cycle List'),
    tran_modeList: user.permissions.includes('Tran Mode List'),
    service_typeList: user.permissions.includes('Service Type List'),
    industry_typeList: user.permissions.includes('Industry Type List'),
    customer_sourceList: user.permissions.includes('Customer Source List'),
    customer_typeList: user.permissions.includes('Customer Type List'),
    provinceList: user.permissions.includes('Province List'),
    districtList: user.permissions.includes('District List'),
    agentList: user.permissions.includes('Agent List'),
    agentView: user.permissions.includes('Agent View'),
    popsList: user.permissions.includes('POP List'),
    invoice_templateList: user.permissions.includes('Invoice Template List'),
    contactList: user.permissions.includes('Contact List'),
    customerList: user.permissions.includes('Customer List'),
    customerView: user.permissions.includes('Customer View'),
    customerSetUpList: user.permissions.includes('Customer SetUp'),
    contactSetUpList: user.permissions.includes('Contact SetUp'),
    subscriptionSetUpList: user.permissions.includes('Subscription SetUp'),
    tariffList: user.permissions.includes('Tariff Internet List'),
    invoiceStatusList: user.permissions.includes('Invoice Status List'),
    venderServiceList: user.permissions.includes('Vender Service List'),
    billItemList: user.permissions.includes('Bill Item List'),
    paymentOptionList: user.permissions.includes('Payment Option List'),
    paymentStatusList: user.permissions.includes('Payment Status List'),
    emailTemplateList: user.permissions.includes('Email Template List'),
    currencyList: user.permissions.includes('Currency List'),
    runbilloperationList: user.permissions.includes('Run Bill Operation List'),
    invoicesList: user.permissions.includes('Invoices List'),
    internalReport: user.permissions.includes('Internal Report List'),
    externalReport: user.permissions.includes('External Report List'),
    searchInvoiceList: user.permissions.includes('Search Invoice List'),
    unpaidInvoiceList: user.permissions.includes('Unpaid invoice List'),
    transactionLogList: user.permissions.includes('Transaction Log List'),
    customerBalanceList: user.permissions.includes('Customer Balance List'),
    customerReportList: user.permissions.includes('Customer Report List'),
    receiptReportList: user.permissions.includes('Receipt Report List'),
    subscriptionReportList: user.permissions.includes('Subscription Report List'),
    invoiceReportList: user.permissions.includes('Invoice Report List'),
    customerSubscriptionReportList: user.permissions.includes('Customer Subscription Report List'),
    subscriptionServiceReportList: user.permissions.includes('Subscription Service Report List'),
    invoiceSummaryList: user.permissions.includes('Invoice Summary List'),
    broadCastEmailList: user.permissions.includes('BroadCast Email List'),
    searchCustomerList: user.permissions.includes('Search Customer List'),
    paymentMonitorList: user.permissions.includes('To Be Renew List'),
    subscriptionsList: user.permissions.includes('Subscriptions List'),
    devicesList: user.permissions.includes('Devices List'),
    devicesDetailList: user.permissions.includes('Devices Detail'),
    venderContractList: user.permissions.includes('Vender Contract List'),
    subscriptionHistoryList: user.permissions.includes('Subscription History List'),
    networkingList: user.permissions.includes('Subscription NetWorkingSetting'),
    interfaceList: user.permissions.includes('Interface List'),
    vlanList: user.permissions.includes('VLAN List'),
    serviceItemListShow: user.permissions.includes('Service Item List Show'),
    unpaidReportList: user.permissions.includes('Unpaid Report List'),
    invoiceSummaryHistoryList: user.permissions.includes('Invoice Summary History List'),
  }

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const Document = lazy(() => import('../pages/document/Document'))
  const Administrator = lazy(() => import('../pages/administrator/Administrator'))
  const AdministratorDetail = lazy(() => import('../pages/administrator/AdministratorDetail'))
  const Role = lazy(() => import('../pages/role/Role'))
  const RoleDetail = lazy(() => import('../pages/role/RoleDetail'))
  const Menu = lazy(() => import('../pages/menu/Menu'))
  const Permission = lazy(() => import('../pages/permission/Permission'))
  const Vender = lazy(() => import('../pages/vender/Vender'))
  const VenderService = lazy(() => import('../pages/vender-service/VenderService'))
  const Service = lazy(() => import('../pages/service/Service'))
  const TrandMode = lazy(() => import('../pages/trandMode/TrandMode'))
  const BillCycle = lazy(() => import('../pages/billCycle/BillCycle'))
  const ServiceType = lazy(() => import('../pages/serviceType/ServiceType'))
  //!NOT YET
  const Tariff = lazy(() => import('../pages/tariff/Tariff'))
  const IndustryType = lazy(() => import('../pages/industryType/IndustryType'))
  const CustomerSource = lazy(() => import('../pages/customerSource/CustomerSource'))
  const CustomerType = lazy(() => import('../pages/customerType/CustomerType'))
  const Province = lazy(() => import('../pages/province/Province'))
  const District = lazy(() => import('../pages/district/District'))
  const Agent = lazy(() => import('../pages/agent/Agent'))
  const AgentDetail = lazy(() => import('../pages/agent/AgentDetail'))
  const Pops = lazy(() => import('../pages/pops/Pops'))
  const InvoiceTemplate = lazy(() => import('../pages/invoiceTemplate/InvoiveTemplate'))
  const Customer = lazy(() => import('../pages/customer/CustomerTable'))
  const CustomerDetail = lazy(() => import('../pages/customer/CustomerDetail'))
  const CustomerSetUp = lazy(() => import('../pages/customer/CustomerSetUp'))
  const ContactSetUp = lazy(() => import('../pages/customer/Contact'))
  const SubscriptionSetUp = lazy(() => import('../pages/customer/Subscription'))
  const UserInfo = lazy(() => import('../pages/customer/detail/UserInfo'))
  const InvoiceStatus = lazy(() => import('../pages/InvoiceStatus/InvoiceStatus'))
  const BillItem = lazy(() => import('../pages/Bill-Item/BillItem'))
  const Paymentoption = lazy(() => import('../pages/Payment-Option/PaymentOption'))
  const PaymentStatus = lazy(() => import('../pages/Payment-status/PaymenyStatus'))
  const EmailTemplate = lazy(() => import('../pages/Email-template/EmailTemplate'))
  const Currency = lazy(() => import('../pages/currency/Currency'))
  const RunBillOperation = lazy(() => import('../pages/bill-run-operation/RunBillOperation'))
  const Invoices = lazy(() => import('../pages/Invoices/Invoices'))
  const SearchCustomer = lazy(() => import('../pages/search-customer/SearchCustomer'))
  const Tariffs = lazy(() => import('../pages/tariffs/Tariff'))
  const TariffAdd = lazy(() => import('../pages/tariffs/TariffAdd'))
  const PaymentMonitor = lazy(() => import('../pages/payemnt-monitor/PaymentMonitor'))
  const TransactionLog = lazy(() => import('../pages/transaction-log/TransactionLog'))
  const UnpaidInvoice = lazy(() => import('../pages/unpaid-invoice/UnpaidInvoice'))
  const CustomerBalance = lazy(() => import('../pages/customer-balance/CustomerBalance'))
  const SearchInvoice = lazy(() => import('../pages/search-invoice/SearchInvoice'))
  const CustomerReport = lazy(() => import('../pages/customer-report/CustomerReport'))
  const ReceipReport = lazy(() => import('../pages/receip-report/ReceipReport'))
  const SubscriptionReport = lazy(() => import('../pages/subscription-report/SunscriptionReport'))
  const InvoiceReport = lazy(() => import('../pages/invoice-report/InvoiceReport'))
  const CusSubReport = lazy(() => import('../pages/customer-subscription-report/CusSubReport'))
  const SubServiceReport = lazy(() => import('../pages/subscription-service-report/SubServiceReport'))
  const InvoiceSummary = lazy(() => import('../pages/invoice-summary/InvoiceSummary'))
  const InteralReport = lazy(() => import('../pages/report/internal-report/InternalReport'))
  const BroadCast = lazy(() => import('../pages/broadcast-email/BroadCast'))
  // const NetWorking = lazy(() => import('../pages/customer/NetworkingSetUp'))
  // const NetWorkingDetail= lazy(() => import('../pages/customer/detail/NetworkingDetail'))
  const BillCycleReport = lazy(() => import('../pages/bill-cycle-report/BillCycleReport'))
  const Subscription = lazy(() => import('../pages/subscription/Subscription'))
  const RouterDevice = lazy(() => import('../pages/router-device/RouterDevice'))
  const RouterDeviceDetail = lazy(() => import('../pages/router-device/RouterDeviceDetail'))
  const VenderContract = lazy(() => import('../pages/vender-contract/VenderContract'))
  const SubscriptionHistory = lazy(() => import('../pages/subscription-history/SubscriptionHistory'))
  const Vlan = lazy(() => import('../pages/vlan/Vlan'))
  const Interface = lazy(() => import('../pages/interface/Interface'))
  const ServiceItemList = lazy(() => import('../pages/service-item-list/ServiceItemList'))
  const UnpaidReport = lazy(() => import('../pages/unpaid-report/UnpaidReport'))
  const InvoiceSummaryHistory = lazy(() => import('../pages/invoice-summary-history/InvoiceSumaryHistory'))

  const Location = lazy(() => import('../pages/Location/Location'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/document' component={Document} />
        {/* Account user */}
        <Route path='/account' component={AccountPage} />

        {/* Role */}
        <Route path='/role/:id/detail' render={({ match, history }) => userCan.roleView ? <RoleDetail match={match} history={history} /> : <NoPermission />} />
        <Route path='/role' render={() => userCan.roleList ? <Role /> : <NoPermission />} />

        {/* Administrator */}
        <Route path='/administrator/:id/detail' render={({ match }) => userCan.administratorView ?
          <AdministratorDetail match={match} /> :
          <NoPermission />
        } />
        <Route path='/administrator' render={() => userCan.administratorList ? <Administrator /> : <NoPermission />} />

        {/* Menu */}
        <Route path='/menu' render={() => userCan.menuList ? <Menu /> : <NoPermission />} />

        {/* Permission */}
        <Route path='/permission' render={() => userCan.permissionList ? <Permission /> : <NoPermission />} />

        {/* Vender */}
        <Route path='/vendor' render={() => userCan.venderList ? <Vender /> : <NoPermission />} />

        {/* Vender-Service */}
        <Route path='/Vender-Service' render={() => userCan.venderServiceList ? <VenderService /> : <NoPermission />} />

        {/* Service */}
        <Route path='/service/:id/:text' render={({ match }) => userCan.serviceList ? <Service match={match} /> : <NoPermission />} />
        <Route path='/service' render={({ match }) => userCan.serviceList ? <Service match={match} /> : <NoPermission />} />

        {/* TrandMode */}
        <Route path='/trandmode/:id/:text' render={({ match }) => userCan.tran_modeList ? <TrandMode match={match} /> : <NoPermission />} />
        <Route path='/trandmode' render={({ match }) => userCan.tran_modeList ? <TrandMode match={match} /> : <NoPermission />} />

        {/* BillCycle */}
        <Route path='/billcycle/:id/:text' render={({ match }) => userCan.bill_cycleList ? <BillCycle match={match} /> : <NoPermission />} />
        <Route path='/billcycle' render={({ match }) => userCan.bill_cycleList ? <BillCycle match={match} /> : <NoPermission />} />

        {/* ServiceType */}
        <Route path='/serviceType/:id/:text' render={({ match }) => userCan.service_typeList ? <ServiceType match={match} /> : <NoPermission />} />
        <Route path='/serviceType' render={({ match }) => userCan.service_typeList ? <ServiceType match={match} /> : <NoPermission />} />

        {/* Tariff */}
        <Route path='/tariff' render={() => userCan.tariffList ? <Tariff /> : <NoPermission />} />

        {/* IndustryType */}
        <Route path='/industryType/:id/:text' render={({ match }) => userCan.industry_typeList ? <IndustryType match={match} /> : <NoPermission />} />
        <Route path='/industryType' render={({ match }) => userCan.industry_typeList ? <IndustryType match={match} /> : <NoPermission />} />

        {/* Customer Source */}
        <Route path='/customerSource/:id/:text' render={({ match }) => userCan.customer_sourceList ? <CustomerSource match={match} /> : <NoPermission />} />
        <Route path='/customerSource' render={({ match }) => userCan.customer_sourceList ? <CustomerSource match={match} /> : <NoPermission />} />

        {/* Customer Type */}
        <Route path='/customertype/:id/:text' render={({ match }) => userCan.customer_typeList ? <CustomerType match={match} /> : <NoPermission />} />
        <Route path='/customertype' render={({ match }) => userCan.customer_typeList ? <CustomerType match={match} /> : <NoPermission />} />

        {/* Province */}
        <Route path='/province/:id/:text' render={({ match }) => userCan.provinceList ? <Province match={match} /> : <NoPermission />} />
        <Route path='/province' render={({ match }) => userCan.provinceList ? <Province match={match} /> : <NoPermission />} />

        {/* District */}
        <Route path='/district/:id/:text' render={({ match }) => userCan.districtList ? <District match={match} /> : <NoPermission />} />
        <Route path='/district' render={({ match }) => userCan.districtList ? <District match={match} /> : <NoPermission />} />

        {/* Agent */}
        <Route path='/agent/:id/detail' render={({ match }) => userCan.agentView ?
          <AgentDetail match={match} /> :
          <NoPermission />
        } />
        <Route path='/agent' render={() => userCan.agentList ? <Agent /> : <NoPermission />} />

        {/* Pops */}
        <Route path='/pops' render={() => userCan.popsList ? <Pops /> : <NoPermission />} />

        {/* Invoice Template */}
        <Route path='/invoice-template/:id/:text' render={({ match }) => userCan.invoice_templateList ? <InvoiceTemplate match={match} /> : <NoPermission />} />
        <Route path='/invoice-template' render={({ match }) => userCan.invoice_templateList ? <InvoiceTemplate match={match} /> : <NoPermission />} />

        {/* Invoices */}
        <Route path='/Invoices' render={() => userCan.invoicesList ? <Invoices /> : <NoPermission />} />

        {/* Customer */}
        <Route path='/customer/:id/:agentid/detail' render={({ match }) => userCan.customerView ?
          <CustomerDetail>
            <UserInfo match={match} />
          </CustomerDetail>
          :
          <NoPermission />
        } />
        <Route path='/customer' render={() => userCan.customerList ? <Customer /> : <NoPermission />} />

        {/* Customer SetUp */}
        <Route path='/setup-customer' render={() => userCan.customerSetUpList ? <CustomerSetUp /> : <NoPermission />} />
        {/* Contact SetUp */}
        <Route path='/contact-setup/:id/:agentid' render={({ match }) => userCan.contactSetUpList ?
          <ContactSetUp match={match} /> :
          <NoPermission />
        } />
        {/* Subscription SetUp */}
        <Route path='/subscription-setup/:id/:agentid' render={({ match }) => userCan.subscriptionSetUpList ?
          <SubscriptionSetUp match={match} /> :
          <NoPermission />
        } />
        {/* Networking SetUp */}
        {/* Detail */}
        {/* <Route path='/networking-setup/:id/:agentid/:subid/:subnetid/Detail' render={({ match }) => userCan.networkingList ?
          <NetWorkingDetail match={match} /> :
          <NoPermission />
        } /> */}
        {/* Sipn Up */}
        {/* <Route path='/networking-setup/:id/:agentid/:subid/:subnetid' render={({ match }) => userCan.networkingList ?
          <NetWorking match={match} /> :
          <NoPermission />
        } /> */}

        {/* Invoice Status */}
        <Route path='/invoice-status/:id/:text' render={({ match }) => userCan.invoiceStatusList ? <InvoiceStatus match={match} /> : <NoPermission />} />
        <Route path='/invoice-status' render={({ match }) => userCan.invoiceStatusList ? <InvoiceStatus match={match} /> : <NoPermission />} />
        {/* Bill-item */}
        <Route path='/bill-item/:id/:text' render={({ match }) => userCan.billItemList ? <BillItem match={match} /> : <NoPermission />} />
        <Route path='/bill-item' render={({ match }) => userCan.billItemList ? <BillItem match={match} /> : <NoPermission />} />
        {/* Paymeny Option */}
        <Route path='/payment-option/:id/:text' render={({ match }) => userCan.paymentOptionList ? <Paymentoption match={match} /> : <NoPermission />} />
        <Route path='/payment-option' render={({ match }) => userCan.paymentOptionList ? <Paymentoption match={match} /> : <NoPermission />} />
        {/* Paymeny Status */}
        <Route path='/payment-status/:id/:text' render={({ match }) => userCan.paymentStatusList ? <PaymentStatus match={match} /> : <NoPermission />} />
        <Route path='/payment-status' render={({ match }) => userCan.paymentStatusList ? <PaymentStatus match={match} /> : <NoPermission />} />
        {/* EmailTemplate */}
        <Route path='/email-template/:id/:text' render={({ match }) => userCan.emailTemplateList ? <EmailTemplate match={match} /> : <NoPermission />} />
        <Route path='/email-template' render={({ match }) => userCan.emailTemplateList ? <EmailTemplate match={match} /> : <NoPermission />} />
        {/* Currency */}
        <Route path='/currency' render={() => userCan.currencyList ? <Currency /> : <NoPermission />} />
        {/* Bill Run Options */}
        <Route path='/run-bill-operation' render={() => userCan.runbilloperationList ? <RunBillOperation /> : <NoPermission />} />
        {/* Bill Run Options */}
        <Route path='/search-customer/:search' render={({ match }) => userCan.searchCustomerList ? <SearchCustomer match={match} /> : <NoPermission />} />
        <Route path='/search-customer' render={({ match }) => userCan.searchCustomerList ? <SearchCustomer match={match} /> : <NoPermission />} />
        {/* to Be Renew */}
        <Route path='/toberenew' render={() => userCan.paymentMonitorList ? <PaymentMonitor /> : <NoPermission />} />
        {/* Transaction log */}
        <Route path='/transaction-log/:id/:text' render={({ match }) => userCan.transactionLogList ? <TransactionLog match={match} /> : <NoPermission />} />
        <Route path='/transaction-log' render={({ match }) => userCan.transactionLogList ? <TransactionLog match={match} /> : <NoPermission />} />
        {/* Unpaid Invoice */}
        <Route path='/unpaid-invoice/:id/:text' render={({ match }) => userCan.unpaidInvoiceList ? <UnpaidInvoice match={match} /> : <NoPermission />} />
        <Route path='/unpaid-invoice' render={({ match }) => userCan.unpaidInvoiceList ? <UnpaidInvoice match={match} /> : <NoPermission />} />
        {/* Customer Balance */}
        <Route path='/balance-customer/:id/:text' render={({ match }) => userCan.customerBalanceList ? <CustomerBalance match={match} /> : <NoPermission />} />
        <Route path='/balance-customer' render={({ match }) => userCan.customerBalanceList ? <CustomerBalance match={match} /> : <NoPermission />} />
        {/* Search Invoice */}
        <Route path='/search-invoice/:id/:text' render={({ match }) => userCan.searchInvoiceList ? <SearchInvoice match={match} /> : <NoPermission />} />
        <Route path='/search-invoice' render={({ match }) => userCan.searchInvoiceList ? <SearchInvoice match={match} /> : <NoPermission />} />
        {/* Customer Report */}
        <Route path='/report-customer/:id/:text' render={({ match }) => userCan.customerReportList ? <CustomerReport match={match} /> : <NoPermission />} />
        <Route path='/report-customer' render={({ match }) => userCan.customerReportList ? <CustomerReport match={match} /> : <NoPermission />} />
        {/* Receip Report */}
        <Route path='/receip-report/:id/:text' render={({ match }) => userCan.receiptReportList ? <ReceipReport match={match} /> : <NoPermission />} />
        <Route path='/receip-report' render={({ match }) => userCan.receiptReportList ? <ReceipReport match={match} /> : <NoPermission />} />
        {/* Subscription Report */}
        <Route path='/subscription-report/:id/:text' render={({ match }) => userCan.subscriptionReportList ? <SubscriptionReport match={match} /> : <NoPermission />} />
        <Route path='/subscription-report' render={({ match }) => userCan.subscriptionReportList ? <SubscriptionReport match={match} /> : <NoPermission />} />
        {/* Invoice Report */}
        <Route path='/invoice-report/:id/:text' render={({ match }) => userCan.invoiceReportList ? <InvoiceReport match={match} /> : <NoPermission />} />
        <Route path='/invoice-report' render={({ match }) => userCan.invoiceReportList ? <InvoiceReport match={match} /> : <NoPermission />} />
        {/* Customer Subscription Report */}
        <Route path='/customer-subscription-report/:id/:text' render={({ match }) => userCan.customerSubscriptionReportList ? <CusSubReport match={match} /> : <NoPermission />} />
        <Route path='/customer-subscription-report' render={({ match }) => userCan.customerSubscriptionReportList ? <CusSubReport match={match} /> : <NoPermission />} />
        {/* Subscription Service Report */}
        <Route path='/subscription-service-report/:id/:text' render={({ match }) => userCan.subscriptionServiceReportList ? <SubServiceReport match={match} /> : <NoPermission />} />
        <Route path='/subscription-service-report' render={({ match }) => userCan.subscriptionServiceReportList ? <SubServiceReport match={match} /> : <NoPermission />} />
        {/* Invoice Summary */}
        <Route path='/summary-invoice/:id/:text' render={({ match }) => userCan.invoiceSummaryList ? <InvoiceSummary match={match} /> : <NoPermission />} />
        <Route path='/summary-invoice' render={({ match }) => userCan.invoiceSummaryList ? <InvoiceSummary match={match} /> : <NoPermission />} />
        {/* Bill Cycle Report */}
        <Route path='/bill-cycle-report/:id/:text' render={({ match }) => userCan.invoiceReportList ? <BillCycleReport match={match} /> : <NoPermission />} />
        <Route path='/bill-cycle-report' render={({ match }) => userCan.invoiceReportList ? <BillCycleReport match={match} /> : <NoPermission />} />

        {/* BroadCast Email */}
        <Route path='/broadcast-email' render={() => userCan.broadCastEmailList ? <BroadCast /> : <NoPermission />} />

        {/* Report Menu */}
        <Route path='/menu-list-report/:id/:text' render={({ match }) => <InteralReport match={match} />} />

        {/* Subscription */}
        <Route path='/subscriptions' render={() => userCan.subscriptionsList ? <Subscription /> : <NoPermission />} />
        {/* Devices */}
        <Route path='/device/:id/detail' render={({ match }) => userCan.devicesDetailList ?
          <RouterDeviceDetail match={match} />
          :
          <NoPermission />
        } />
        <Route path='/device' render={() => userCan.devicesList ? <RouterDevice /> : <NoPermission />} />

        {/* Vender Contract */}
        <Route path='/vender-contract' render={() => userCan.venderContractList ? <VenderContract /> : <NoPermission />} />
        {/* Subscription History */}
        <Route path='/subscription-history/:id/:text' render={({ match }) => userCan.subscriptionHistoryList ? <SubscriptionHistory match={match} /> : <NoPermission />} />
        <Route path='/subscription-history' render={({ match }) => userCan.subscriptionHistoryList ? <SubscriptionHistory match={match} /> : <NoPermission />} />

        {/* Interface */}
        <Route path='/interface' render={() => userCan.interfaceList ? <Interface /> : <NoPermission />} />
        {/* Vlan */}
        <Route path='/vlan' render={() => userCan.vlanList ? <Vlan /> : <NoPermission />} />

        {/* Service Item List */}
        <Route path='/serviceitem-list/:id/:text' render={({ match }) => userCan.serviceItemListShow ? <ServiceItemList match={match} /> : <NoPermission />} />
        <Route path='/serviceitem-list' render={({ match }) => userCan.serviceItemListShow ? <ServiceItemList match={match} /> : <NoPermission />} />

        {/* Unpaid Report */}
        <Route path='/unpaid-report/:id/:text' render={({ match }) => userCan.unpaidReportList ? <UnpaidReport match={match} /> : <NoPermission />} />
        <Route path='/unpaid-report' render={({ match }) => userCan.unpaidReportList ? <UnpaidReport match={match} /> : <NoPermission />} />

        {/* Invoice Summary History */}
        <Route path='/invoice-summary-history/:id/:text' render={({ match }) => userCan.invoiceSummaryHistoryList ? <InvoiceSummaryHistory match={match} /> : <NoPermission />} />
        <Route path='/invoice-summary-history' render={({ match }) => userCan.invoiceSummaryHistoryList ? <InvoiceSummaryHistory match={match} /> : <NoPermission />} />

        {/* Location */}
        <Route path='/location' component={Location} />
        <Route path='/tariff-add' component={TariffAdd} />
        <Route path='/tariffs' component={Tariffs} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
