/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import axios from 'axios'
import { CalenderDropdown } from './custom/CalenderDropdown'
import { DayDropdown } from './custom/DayDropdown'
import { Link } from 'react-router-dom'

//this month 
const s_month = new Date()
const e_month = new Date()
const this_month_end = new Date(e_month.getFullYear(), e_month.getMonth() + 1, 0)
const this_month_start = new Date(s_month.setDate(1))

type SubProps = {
  color: string
  title: string
  subtitle: string
  icon: string
  status: number
}

const NewCustomer: React.FC<SubProps> = ({ color, title, subtitle, icon, status }) => {
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [formInput, setFormInput] = useState({
    DateStart: this_month_start.toISOString().slice(0, 10),
    DateEnd: '',
  })
  const selectionRange = {
    startDate: this_month_start,
    endDate: this_month_end,
    key: 'data',
  }
  const [dateRange, setDateRange] = useState(selectionRange)

  const [counts, setCounts] = useState()

  useEffect(() => {
    setLoading(true)
    axios
      .post(`/customer/search-customer-count/${status}`, formInput)
      .then((resp) => {
        setCounts(resp.data.count)
        const delayDebounceFn = setTimeout(() => {
          setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }, [dispatch, formInput, status])
  return (
    <div className={`bg-${color} px-6 py-8 rounded-2 position-relative d-flex justify-content-between align-items-center`}>
      <div>
        <KTSVG
          path={icon}
          className={`svg-icon-3x svg-icon-white d-block my-2`}
        />
        <Link to='#' className={`text-white fw-bold fs-6`}>
          {title}
        </Link>
      </div>
      <span className={`fw-bolder fs-1 mb-2 text-white`}>
        {loading ?
          <span className={`spinner-border text-white`}></span>
          :
          <span className={`fw-bolder mb-2 text-white`}>
            {formInput.DateStart ? counts : 0}
          </span>
        }
      </span>
      <button
        type='button'
        className={`btn btn-sm btn-icon btn-color-white btn-active-${color} border-0 me-n3 position-absolute top-0`}
        style={{ right: 10 }}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='top-start'
        data-kt-menu-flip='top-start'
      >
        <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1' />
      </button>
      <CalenderDropdown formInput={formInput} setFormInput={setFormInput} dateRange={dateRange} setDateRange={setDateRange} color={color} />
    </div>
  )
}

type CusProp = {
  color: string
  title: string
  subtitle: string
  icon: string
  status: number
}
const ToBeNew: React.FC<CusProp> = ({ color, title, subtitle, icon, status }) => {
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [formInput, setFormInput] = useState({
    day: 25
  })

  const [counts, setCounts] = useState()

  useEffect(() => {

    setLoading(true)
    axios
      .post(`/subscription/search-subscription-monitor-count`, formInput)
      .then((resp) => {
        setCounts(resp.data.count)
        const delayDebounceFn = setTimeout(() => {
          setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }, [dispatch, formInput, status])
  return (
    <div className={`bg-${color} px-6 py-8 rounded-2 position-relative d-flex justify-content-between align-items-center`}>
      <div>
        <KTSVG
          path={icon}
          className={`svg-icon-3x svg-icon-white d-block my-2`}
        />
        <Link to='/toberenew' className={`text-white fw-bold fs-6`}>
          {title}
        </Link>
      </div>
      <span className={`fw-bolder fs-1 mb-2 text-white`}>
        {loading ?
          <span className={`spinner-border text-white`}></span>
          :
          <span className={`fw-bolder mb-2 text-white`}>{formInput.day ? counts : 0}</span>
        }
      </span>
      <button
        type='button'
        className={`btn btn-sm btn-icon btn-color-white btn-active-${color} border-0 me-n3 position-absolute top-0`}
        style={{ right: 10 }}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='top-start'
        data-kt-menu-flip='top-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1' />
      </button>
      <DayDropdown formInput={formInput} setFormInput={setFormInput} color={color} />
    </div>
  )
}

type Props = {
  className: string
}

const NewDash: React.FC<Props> = ({ className }) => {

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0`}>
        <h3 className='card-title fw-bolder text-dark'>Customer</h3>
      </div>
      <div className='card-body pt-0'>
        <div className='mb-3'>
          <NewCustomer
            color='success'
            title='New Customer'
            subtitle='Total active subscription'
            icon='/media/icons/duotune/communication/com006.svg'
            status={-1}
          />
        </div>
        <ToBeNew
          color='danger'
          title='To Be Renew'
          subtitle='Total active subscription'
          icon='/media/icons/duotune/arrows/arr058.svg'
          status={1}
        />
      </div>
      {/* <div
        className='flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom h-200px'
        style={{
          backgroundImage: `url('${('/media/illustrations/dozzy-1/terms-2.png')}')`,
        }}
      ></div> */}
    </div>
  )
}

export { NewDash }
