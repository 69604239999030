import { useContext, useEffect, useRef, useState } from "react"
import ApexCharts from 'apexcharts'
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"
import { DataContext } from "../../../../reducer/GlobalState"
import axios from "axios"


export const ChartPaidUnpaid: React.FC<{ className: string }> = ({ className }) => {

  const { dispatch } = useContext(DataContext)
  const chartRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/invoice/invoice-paid-unpaid-chart`)
      .then((resp) => {
        setLoading(false)
        const chart = new ApexCharts(chartRef.current, chartOption(resp.data))
        const delayDebounceFn = setTimeout(() => {
          if (chart) {
            chart.render()
          }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    //Refresh 5min per time
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, refresh])

  return (
    // <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
    <div className={`${className} h-400px mb-5`}>
      {loading ?
        <div className='position-absolute top-50 start-50 translate-middle'>
          <span className={`spinner-border text-primary`}></span>
        </div>
        :
        <div className='card-body  p-0'>
          <div className='flex-grow-1 card-p pb-0'>
            <div className='d-flex flex-stack'>
              <div className='me-2'>
                <h3 className='fw-bolder text-dark'>Invoice</h3>
              </div>
            </div>
          </div>
          <div ref={chartRef} className='mixed-widget-7-chart'></div>
        </div>
      }
    </div>
  )
}

const chartOption = ({ data_total_paid, data_total_unpaid, data_month }: any) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-danger')
  const baselightColor = getCSSVariableValue('--bs-light-primary')
  const borderCOlor = getCSSVariableValue('--bs-light-dark')
  const labelColor = getCSSVariableValue('--bs-gray-500')
  return {
    series: [
      {
        name: 'Paid',
        data: data_total_paid,
      },
      {
        name: 'Unpiad',
        data: data_total_unpaid,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: '300px',
      zoom: {
        enabled: false,
      },
      // sparkline: {
      //   enabled: true,
      // },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data_month,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    stroke: {
      show: true,
      // width: 2,
      // colors: ['transparent'],
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val: any) {
          return '$' + val
        },
      },
    },
    colors: [baseColor, secondaryColor],
    markers: {
      colors: [baselightColor],
      strokeColors: [baseColor, secondaryColor],
      strokeWidth: 3,
    },
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderCOlor,
      strokeDashArray: 10,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}


