import { useState } from "react"
import { Form } from "react-bootstrap-v5"
type props = {
  formInput: any
  setFormInput: any
  color: string
}
export const DayDropdown: React.FC<props> = ({ formInput, setFormInput, color }) => {
  const [days, setDays] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput({ ...formInput, day: e.target.value })
    setDays(e.target.value)
    setTimeout(() => {
      setFormInput({ ...formInput, day: 25 })
      setDays('')
    }, 300000)
  }

  return (
    <div className={`menu menu-sub menu-sub-dropdown border border-${color} border-1 w-300px`} data-kt-menu='true'>
      <div className='px-7 py-5'>
        <Form.Label>Filter by Day</Form.Label>
        <Form.Control
          id='day'
          type='number'
          size='sm'
          value={days}
          className={`form-control-solid`}
          onChange={handleChange}
          placeholder='Days'
        />
      </div>
    </div>
  )
}
