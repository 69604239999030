import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import SubscriptionsItem from './components/SubscriptionsItem'
import { DataContext } from '../../../../reducer/GlobalState'
import { MyPagination } from '../../../../../components/MyPagination'
import clsx from 'clsx'


const num_page = 10

function Subscriptions({className}) {

  const { dispatch } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [Profile, setProfile] = useState([])
  const [subscriptions, setSubscriptions] = useState([])


  const [activate, setActivate] = useState(1)
  const [page, setPage] = useState(1)
  const [showId, setShowId] = useState(0)

  const dataFetch = async () => {
    setLoading(true)
    await axios
      .get(`/subscription/service-status-subscription-count`)
      .then((resp) => {
        if (resp.data) {
          setProfile(resp.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }

  const SubscriptionFetch = async (i) => {
    setActivate(i)
    setPage(1)
    setShowId(0)
    setIsLoading(true)
    await axios
      .get(`/subscription/subscription-service-status-list/${i}`)
      .then((resp) => {
        if (resp.data) {
          setIsLoading(false)
          setSubscriptions(resp.data.data)
        }
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }


  useEffect(() => {
    setLoading(true)
    setIsLoading(true)
    const delayDebounceFn = setTimeout(() => {
      dataFetch()
      SubscriptionFetch(1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderServicetype = () => {
    return subscriptions.map((servicetype, idx) => (
      <SubscriptionsItem
        i={idx}
        showId={showId}
        key={idx}
        servicetype={servicetype}
        num_page={num_page}
      />
    ))
  }

  
  return (
    <div className={className}>
      {loading ? <p className='text-center'>Loading...</p> :
        <>
          <div className='card pe-5'>
            <div className='row'>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-dark', { active: activate === -1 })} onClick={() => SubscriptionFetch(-1)}>
                  <div>{Profile.totalSubscriptions}</div>
                  <span>Total</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-muted', { active: activate === 0 })} onClick={() => SubscriptionFetch(0)}>
                  <div>{Profile.isCountInactive}</div>
                  <span>Inactive</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-primary', { active: activate === 1 })} onClick={() => SubscriptionFetch(1)}>
                  <div>{Profile.isCountActive}</div>
                  <span>Active</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-warning', { active: activate === 2 })} onClick={() => SubscriptionFetch(2)}>
                  <div>{Profile.isCountLock}</div>
                  <span>Lock</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-denger', { active: activate === 3 })} onClick={() => SubscriptionFetch(3)}>
                  <div>{Profile.isCountClose}</div>
                  <span>Close</span>
                </button>
              </div>
              <div className='col'>
                <button className={clsx('btn btn-light p-3 m-2 w-100 text-success', { active: activate === 4 })} onClick={() => SubscriptionFetch(4)}>
                  <div>{Profile.isCountSuspended}</div>
                  <span>Suspended</span>
                </button>
              </div>
            </div>
          </div>

          <div className='card card-body pt-0'>
            <div className='table-responsive'>
              <table id='kt_customers_table' className='table table-sm table-hover align-top table-row-dashed fs-6 gy-4'>
                <thead>
                  <tr className='text-start text-gray-400 fw-bolder fs-7 gs-0'>
                    <th className=''>SID</th>
                    <th className=''>Subsription Name</th>
                    <th className=''>Customer Name</th>
                    <th className='min-w-0px'>Tariff Name</th>
                    <th className='min-w-0px'>Status</th>
                  </tr>
                </thead>
                <tbody className='fw-bold text-gray-600'>
                  {isLoading ? (
                    <tr>
                      <td colSpan={5}>
                        <p className='text-center'>Loading...</p>
                      </td>
                    </tr>
                  ) : (
                    subscriptions instanceof Array && renderServicetype()
                  )}
                </tbody>
              </table>
              {!isLoading && (
                <div className='d-flex flex-stack flex-wrap'>
                  <div className='fs-6 fw-bold text-gray-700'>
                    Showing {page} to {Math.ceil(subscriptions.length / num_page)} of {subscriptions.length} entries
                  </div>
                  {subscriptions.length > num_page ? (
                    <MyPagination
                      page={page}
                      totalPages={Math.ceil(subscriptions.length / num_page)}
                      handlePagination={(updatePage) => {
                        setPage(updatePage)
                        setShowId((updatePage - 1) * num_page)
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </>}
    </div>
  )
}
export default Subscriptions