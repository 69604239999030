/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  // EngageWidget5,
} from '../../../_metronic/partials/widgets'
import { Toolbar1 } from '../../../_metronic/layout/components/toolbar/Toolbar1'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import Subscriptions from './components/subscriptions/Subscriptions'
import { FC } from 'react'
import {
  ChartInvoices,
  ChartPaidUnpaid,
  ChartSubscription,
  DashboardCount,
  SubscriptionDash,
  NewDash,
  IncomeDash,
} from './index'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='danger'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5'  */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)



const DashboardWrapper: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const userCan = {
    view: user.permissions.includes(`Dashboard View`),
    technical: user.permissions.includes(`Dashboard Tenical`),
    finance: user.permissions.includes(`Dashboard Finance`),
    invoice: user.permissions.includes(`Dashboard Invoice`),
    subscription: user.permissions.includes(`Dashboard Subscription`),
    income: user.permissions.includes(`Dashboard Income`),
    subscriptionList: user.permissions.includes(`Dashboard Subscription List`),
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <Toolbar1 />
      {userCan.invoice &&
        <>
          <div className='row gy-5'>
            <div className='col-xl-3'>
              <DashboardCount
                className='card card-stretch-30'
                color='dark'
                title='Invoice'
                subtitle='Tolal Invoices'
                icon='/media/icons/duotune/general/gen005.svg'
                status={-1}
                api={`/invoice/search-invoice-dashboard`}
                url='#'
              />
            </div>
            <div className='col-xl-3'>
              <DashboardCount
                className='card card-stretch-30'
                color='primary'
                title='Paid Invoice'
                subtitle='Tolal Paid Invoices'
                icon='/media/icons/duotune/files/fil008.svg'
                status={1}
                api={`/invoice/search-invoice-dashboard`}
                url='#'
              />
            </div>
            <div className='col-xl-3'>
              <DashboardCount
                className='card card-stretch-30'
                color='danger'
                title='Unpaid Invoice'
                subtitle='Tolal Unpaid Invoices'
                icon='/media/icons/duotune/files/fil007.svg'
                status={0}
                api={`/invoice/search-invoice-dashboard`}
                url='/unpaid-invoice'
              />
            </div>
            <div className='col-xl-3'>
              <DashboardCount
                className='card card-stretch-30'
                color='warning'
                title='Cancel Invoice'
                subtitle='Tolal Cancel Invoices'
                icon='/media/icons/duotune/general/gen027.svg'
                status={2}
                api={`/invoice/search-invoice-dashboard`}
                url='#'
              />
            </div>
          </div>
          <div className='row gy-5'>
            <div className='col-xl-6'>
              <ChartInvoices className='card card-stretch' />
            </div>
            <div className='col-xl-6'>
              <ChartPaidUnpaid className='card card-stretch' />
            </div>
          </div>
        </>
      }
      {userCan.subscription && <>
        <div className='row gy-5'>
          <div className='col-xl-3 col-md-12'>
            <NewDash
              className='card-stretch'
            />
          </div>
          <div className='col-xl-3 col-md-12'>
            <SubscriptionDash className='card-stretch' />
          </div>
          <div className='col-xl-6 col-md-12'>
            <ChartSubscription className='card card-stretch' />
          </div>
        </div>
      </>
      }
      {userCan.income && <>
        <div className='row gy-5'>
          <div className='col-xl-4 col-md-12'>
            <IncomeDash
              className='card card-stretch'
              color='warning'
              title='Summary Invoice'
              subtitle='Tolal Invioce'
              icon='/media/icons/duotune/finance/fin010.svg'
              url={`/invoice/search-invoice-income`}
              money={true}
            />
          </div>
          <div className='col-xl-4 col-md-12'>
            <IncomeDash
              className='card card-stretch'
              color='primary'
              title='Internet'
              subtitle='Tolal Internet'
              icon='/media/icons/duotune/finance/fin010.svg'
              status={100}
              url={`/invoice/search-invoice-income-type`}
              money={true}
            />
          </div>
          <div className='col-xl-4 col-md-12'>
            <IncomeDash
              className='card card-stretch'
              color='success'
              title='Installation'
              subtitle='Tolal Installation'
              icon='/media/icons/duotune/finance/fin010.svg'
              status={10}
              url={`/invoice/search-invoice-income-type`}
              money={true}
            />
          </div>
        </div>
      </>
      }
      {userCan.view && <DashboardPage />}
      {userCan.subscriptionList && <Subscriptions className='col-xl-12' />}
    </div>
  )
}

export { DashboardWrapper }
